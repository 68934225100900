.Merchants_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
}

.Merchants_ButtonContainer {
  justify-content: space-around !important;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.375rem;
  margin-bottom: 1.688rem;
}

.Merchants_Icon {
  width: 1.2rem;
  height: 1.2rem;
}

.Merchants_HeaderText {
  padding-top: 12px;
  width: 80%;
  white-space: pre-wrap;
}

.Merchants_TopContainer {
  display: flex;
  padding-top: 1.0625rem;
  padding-bottom: 0.625rem;
  height: 4.875rem;
}

.Merchants_AddButton {
  margin-left: auto !important;
  align-self: center;
  margin-right: 1.5625rem !important;
  width: 215px;
}

.Merchants_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
}

.Merchants_Subheader {
  text-align: left;
  font: normal normal 600 20px/28px Open Sans !important;
  letter-spacing: 0px !important;
  color: var(--primary-purple) !important;
  padding-left: 1.5rem;
  padding-bottom: 0.625rem;
  align-self: end;
}

.Merchants_ActionButtonText {
  padding-left: 0.75rem;
  font: normal normal normal 14px/13px Open Sans;
  letter-spacing: 0px;
  min-width: 5rem;
  text-align: center;
}

.Merchants_actionButton {
  margin-left: auto !important;
  align-self: center;
  margin-right: 1rem !important;
  width: 12.5rem;
}

.Merchants_dialogContainer {
  display: flex;
  flex-direction: column;
}
