.inheritColor {
  color: inherit;
}

.faq_background_box {
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
}

.faq_toggleAllButton {
  width: 300px;
  align-self: center;
}