.actionDialog_container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.5rem !important;
}

.actionDialog_CloseContainer {
  margin-left: auto;
}

.actionDialog_CloseIcon {
  padding: 16px;
}

.actionDialog_Text {
  text-align: center;
  font: normal normal bold 24px/30px Open Sans;
  letter-spacing: 0.18px;
  color: #5f277e;
  opacity: 1;
  margin-top: 0rem;
  margin-bottom: 2rem;
}

.actionDialog_smallText {
  text-align: center;
  font: normal normal normal 16px/25px Open Sans;
  letter-spacing: 0.12px;
  color: #000000;
  opacity: 1;
  margin-top: -1.688rem;
  margin-bottom: 1.875rem;
}

.actionDialog_ButtonContainer {
  justify-content: space-around !important;
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  margin-bottom: 3.75rem;
}

.actionDialog_Button {
  width: 204px;
}

.actionDialog_ButtonText {
  font-size: 1rem;
  text-align: center;
  margin: 0px !important;
}

.timeoutDialog_Text {
  text-align: center;
  font: normal normal bold 24px/30px Open Sans;
  letter-spacing: 0.18px;
  color: #5f277e;
  opacity: 1;
  margin-top: 0rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  white-space: pre-wrap;
}

.timeoutDialog_ButtonContainer {
  justify-content: space-around !important;
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;
  margin-bottom: 1.75rem;
}
