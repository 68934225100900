.stakeholderUploadDialog_container {
  display: flex;
  flex-direction: column;
}

.stakeholderUploadDialog_CloseContainer {
  margin-left: auto;
}

.stakeholderUploadDialog_CloseIcon {
  padding: 1rem;
}

.stakeholderUploadDialog_Text {
  text-align: center;
  font: normal normal bold 1.5rem/1.875rem Open Sans;
  letter-spacing: 0.01125rem;
  margin-top: 0rem;
  margin-bottom: 2rem;
  color: #5f277e;
  opacity: 1;
}

.stakeholderUploadDialog_ButtonContainer {
  justify-content: space-around !important;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.stakeholderUploadDialog_Header {
  display: flex;
  flex-direction: column;
  height: 5.9375rem;
}

.stakeholderUploadDialog_icon {
  width: 6.25rem !important;
  height: 6.25rem !important;
  align-self: center;
}

.stakeholderUploadDialog_smallText {
  white-space: pre-wrap;
  text-align: center;
  font: normal normal 600 1.25rem/1.75rem Open Sans;
  letter-spacing: 0rem;
  color: #515151;
  opacity: 1;
}

.stakeholderUploadDialog_highlight {
  text-decoration: underline;
  font: normal normal 600 1.25rem/1.75rem Open Sans;
  letter-spacing: 0rem;
  color: #14abef;
  cursor: pointer;
}

.stakeholderUploadDialog_Dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.stakeholderUploadDialog_progress {
  display: flex;
  align-self: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.stakeholderUploadDialog_invalid {
  color: red;
}

.stakeholderUploadDialog_uploadIcon {
  width: 5.625rem;
  height: 5.625rem;
  margin-left: auto;
  margin-right: auto;
}

.stakeholderUploadDialog_uploadButton {
  padding-left: 4.6875rem !important;
  padding-right: 4.6875rem !important;
}
