.homePageCard_image_container {
  background-color: #004052;
  height: 12.25rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.homePageCard_image {
  height: fit-content;
  align-self: center;
  max-width: 75%;
  max-height: 75%;
}

.homePageCard_box {
  background: var(--primary-white) 0% 0% no-repeat padding-box;
  box-shadow: 0rem 0.125rem 3rem #faf7fc;
  border: 0.0625rem solid #d8d8d8;
  border-radius: 0.9375rem;
  height: 100%;
  padding: 1.5rem;
  box-sizing: border-box;
}

.homePageCard_header_container {
  display: flex;
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.homePageCard_header {
  margin-top: 0.5rem;
  margin-left: 0.3125rem;
}

.homePageCard_info {
  background: var(--primary-purple-text) 0% 0% no-repeat padding-box;
  border-radius: 100%;
  color: white !important;
  margin: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  height: fit-content;
}
