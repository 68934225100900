.resources_padding {
  padding-bottom: 28px;
}

.resources_admin_padding {
  padding: 28px;
}

.resources_HeaderText {
  padding-top: 12px;
  white-space: pre-wrap;
  width: 70%;
}

.resources_Subheader {
  text-align: left;
  font: normal normal 600 20px/28px Open Sans !important;
  letter-spacing: 0px !important;
  color: var(--primary-purple) !important;
  padding: 24px;
}

.resources_factsheet_box {
  display: flex;
  border: 1px solid rgba(224, 224, 224, 1);
  min-height: 84px;
  align-items: center;
  padding: 0rem 1rem;
  justify-content: space-between;
}

.resources_factsheet_text {
  margin-left: 2rem !important;
  font: normal normal 400 14px/18px Open Sans !important;
  letter-spacing: 0px !important;
  color: #31345b !important;
}

.resources_factsheet_button {
  min-width: 228px;
  min-height: 58px;
}

.resources_factsheet_button_text {
  font: normal normal normal 14px/13px Open Sans;
  letter-spacing: 0px;
  min-width: 142px;
  text-align: center;
}

.resources_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
}
