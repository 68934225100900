.documentTable_selectLabel {
  color: var(--primary-black) !important;
}

.documentTable_header {
  color: #31345b;
  font: normal normal 600 0.8125rem/1.125rem Open Sans;
  letter-spacing: 0rem;
  text-transform: uppercase;
  padding-left: 1rem !important;
}

.documentTable_header:first-of-type {
  padding-left: 2rem !important;
}

.documentTable_header:focus {
  outline: none !important;
}

.documentTable_header:focus-within {
  outline: none !important;
}

.documentTable_seperator {
  display: none;
  visibility: hidden;
}

.documentTable_header_background {
  background: #f7f9fc 0% 0% no-repeat padding-box;
}

.documentTable_header_background:focus {
  outline: none !important;
}

.documentTable_row {
  font: normal normal 500 0.8125rem/1.125rem Open Sans;
  letter-spacing: 0rem;
  color: #31345b;
}

.documentTable_row.Mui-selected {
  background-color: rgba(0, 0, 0, 0) !important;
}

.documentTable_row.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.documentTable_cell_padding {
  padding-left: 1rem !important;
}

.documentTable_cell_padding:first-of-type {
  padding-left: 2rem !important;
}

.documentTable_cell_padding:focus {
  outline: none !important;
}

.documentTable_top_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 0.1875rem 0.375rem #00000029;
  border-radius: 0.625rem;
  opacity: 1;
}

.documentTable_TopContainer {
  display: flex;
  padding-top: 1.0625rem;
  padding-bottom: 0.625rem;
  height: 4.875rem;
}

.documentTable_AddButton {
  margin-left: auto !important;
  align-self: center;
  margin-right: 1.5625rem !important;
}

.documentTable_DownloadText {
  padding-left: 0.75rem;
  font: normal normal normal 0.875rem/0.8125rem Open sans;
  letter-spacing: 0rem;
  min-width: 0.3125rem;
  text-align: center;
}

.documentTable_Subheader {
  text-align: left;
  font: normal normal 600 1.25rem/1.75rem Open Sans !important;
  letter-spacing: 0rem !important;
  color: var(--primary-purple) !important;
  padding-left: 1.5rem;
  padding-bottom: 0.625rem;
  align-self: end;
}

.documentTable_SubheaderCollapsed {
  text-align: left;
  font: normal normal 600 1.25rem/1.75rem Open Sans !important;
  letter-spacing: 0rem !important;
  color: var(--primary-purple) !important;
  padding: 1.5rem;
  padding-right: 0rem;
}

.documentTable_Loading {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.documentTable_closeIcon {
  margin-right: 1rem !important;
  font-size: xx-large !important;
  font-weight: bolder !important;
  color: var(--primary-purple) !important;
  min-width: 2rem;
}

.documentTable_closeIconImg {
  min-width: 1.3em;
  min-height: 1.3em;
}

.documentTable_accordion_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 0.1875rem 0.375rem #00000029 !important;
  border-radius: 0.625rem !important;
  opacity: 1;
  margin: 0rem !important;
}

.documentTable_accordion {
  padding: 0rem !important;
}

.documentTable_accordion_root {
  margin: 0rem !important;
}

.documentTable_accordion_rootExpanded {
  margin: 0rem !important;
  padding-top: 1.0625rem;
  padding-bottom: 0.625rem;
}

.documentTable_accordion_details {
  padding: 0rem !important;
}

.documentTable_cell_content {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  text-align: left;
}
