a {
  color: var(--primary-purple);
  text-decoration: underline;
  display: inline-block;
}

a:hover {
  text-shadow: 0 0 0.0313rem;
  cursor: pointer;
}
