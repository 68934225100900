@media (min-width: 1024px) {
  .gutter {
    padding-left: 8.875rem;
    padding-right: 8.875rem;
  }
  .section_2 {
    padding-top: 4rem;
    padding-bottom: 6rem;
  }
  .section_3 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  .section_4 {
    padding-top: 3.125rem;
    padding-bottom: 3.75rem;
  }
  .section_5 {
    padding-top: 3.125rem;
    padding-bottom: 3.75rem;
  }
}

@media (max-width: 1024px) {
  .gutter {
    padding-left: 5%;
    padding-right: 5%;
  }
  .section_2_image {
    margin-top: 2rem;
  }
  .section_2 {
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  .section_3 {
    padding-top: 1.5rem;
    padding-bottom: 3rem;
  }
  .section_4 {
    padding-top: 0.75rem;
    padding-bottom: 1rem;
  }
  .section_5 {
    padding-top: 3rem;
    padding-bottom: 4rem;
  }
}

.alignLeft {
  text-align: left !important;
}

.section_1_background {
  background: url('../../assets/backgrounds/HomePage_PurpleBanner_1500x.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 4.375rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section_1_button {
  width: fit-content;
  align-self: center;
}

.section_2_background {
  background: var(--dss-light-purple) 0% 0% no-repeat padding-box;
  padding: 4.0625rem 5% 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section_2_contentContainer {
  max-width: 81.25rem;
}

.section_2_divider {
  border-top: 0.1875rem solid #5f277e;
  opacity: 1;
  margin-top: 1.5rem;
  margin-bottom: 2.25rem;
}

.section_2_text {
  text-align: left !important;
  font: normal normal 600 1.375rem/2rem Open Sans !important;
  letter-spacing: 0rem !important;
  color: #222222;
  opacity: 1;
  white-space: pre-wrap;
  width: 90%;
}

.section_2_image_container {
  display: flex;
}

.section_2_image {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.section_3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section_3_info {
  opacity: 1;
  border-radius: 100%;
  height: 100%;
  color: var(--primary-purple-text);
  height: 1.75rem !important;
  margin-right: 0.5rem;
  width: 1.75rem !important;
}

.section_3_highlight {
  text-align: center;
  text-decoration: underline;
  font: normal normal normal 1rem/1.5rem Arial;
  letter-spacing: 0.018125rem !important;
  color: var(--primary-purple-text);
  opacity: 1;
  padding-bottom: 2rem;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: flex-end;
}

.section_3_image_container {
  background-color: #004052;
  height: 8.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.section_3_image {
  height: fit-content;
  align-self: center;
}

.section_3_grid {
  max-width: 65.625rem;
  height: 100%;
}

.section_4 {
  background: var(--dss-light-purple) 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homePage_textBlock_contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50rem;
}

.homePage_textBlock_divider {
  border-top: 0.1875rem solid #5f277e;
  width: 100%;
}

.section_5_header {
  text-align: center;
  font: normal normal bold 2.25rem/2.8125rem Open Sans !important;
  letter-spacing: 0rem !important;
  opacity: 1;
  white-space: pre-wrap;
  width: fit-content;
  align-self: center;
}

.section_5 {
  background: var(--primary-white) 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homepage_h1 {
  font: normal normal bold 2.5rem/3.4375rem Open Sans !important;
  letter-spacing: 0 !important;
  white-space: pre-wrap;
}

.homepage_h2 {
  font: normal normal bold 1.875rem/2.375rem Open Sans !important;
  letter-spacing: 0 !important;
  white-space: pre-wrap;
}

.homepage_h3 {
  font: normal normal bold 1.375rem/1.75rem Open Sans !important;
  letter-spacing: 0 !important;
  white-space: pre-wrap;
}

.homepage_h4 {
  font: normal normal 600 1.375rem/2rem Open Sans !important;
  letter-spacing: 0 !important;
  white-space: pre-wrap;
}

.homepage_h5 {
  font: normal normal normal 1.375rem/1.875rem Open Sans !important;
  letter-spacing: 0 !important;
  white-space: pre-wrap;
}

.homepage_body1 {
  font: normal normal 600 1rem/1.625rem Open Sans !important;
  letter-spacing: 0 !important;
  white-space: pre-wrap;
}

.homepage_body2 {
  font: normal normal 500 1rem/1.625rem Open Sans !important;
  letter-spacing: 0 !important;
  white-space: pre-wrap;
}

.homepage_body3 {
  font: normal normal normal 1rem/1.625rem Open Sans !important;
  letter-spacing: 0 !important;
  white-space: pre-wrap;
}

.black {
  color: var(--primary-black) !important;
}

.white {
  color: var(--primary-white) !important;
}

.purple {
  color: var(--primary-purple) !important;
}

.yellow {
  color: var(--primary-yellow) !important;
}
