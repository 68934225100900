.uploadDialog_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0.5rem !important;
}

.uploadDialog_CloseContainer {
  margin-left: auto;
}

.uploadDialog_CloseIcon {
  padding: 1rem;
}

.uploadDialog_Text {
  text-align: center;
  font: normal normal bold 1.5rem/1.875rem Open Sans;
  letter-spacing: 0.01125rem;
  margin-top: 0rem;
  color: #5f277e;
  opacity: 1;
}

.uploadDialog_ButtonContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.uploadDialog_Header {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.5rem !important;
}

.uploadDialog_icon {
  width: 6.25rem !important;
  height: 6.25rem !important;
  align-self: center;
}

.uploadDialog_smallText {
  white-space: pre-wrap;
  text-align: center;
  font: normal normal 600 1.25rem/1.75rem Open Sans;
  letter-spacing: 0rem;
  color: #31345b;
  opacity: 1;
}

.uploadDialog_highlight {
  text-decoration: underline;
  font: normal normal 600 1.25rem/1.75rem Open Sans;
  letter-spacing: 0rem;
  color: #14abef;
  cursor: pointer;
}

.uploadDialog_Dropzone {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  padding-top: 1rem;
}

.uploadDialog_progress {
  align-self: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.uploadDialog_flexRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.uploadDialog_checkBoxText {
  margin-right: 0.625rem;
  font: normal normal 600 1.25rem/1.75rem Open Sans;
  letter-spacing: 0rem;
  color: #515151;
  opacity: 1;
}

.uploadDialog_dialogText {
  display: flex;
  justify-content: center;
  align-content: center;
  font: normal normal normal 1rem/1.5625rem Open Sans;
  flex-direction: row;
}

.uploadDialog_dialogTextAlign {
  display: flex;
  align-content: flex-start;
  font: normal normal normal 1rem/1.5625rem Open Sans;
  flex-direction: row;
}

.uploadDialog_uploadIcon {
  width: 5.625rem;
  height: 5.625rem;
  margin-left: auto;
  margin-right: auto;
}

.uploadDialog_uploadButton {
  padding-left: 4.6875rem !important;
  padding-right: 4.6875rem !important;
}
