body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

:root {
  --primary-white: #ffffff;
  --primary-black: #000000;
  --primary-purple: #5f277e;
  --primary-purple-hover: #8432b1;
  --secondary-purple: #8342a7;
  --secondary-purple-hover: #f1e6f5;
  --dss-light-purple: #faf7fc;
  --primary-purple-text: #500a78;
  --primary-yellow: #fee98e;
  --secondary-yellow: #ffc820;
  --dark-yellow: #e6b000;
  --primary-dark-gray: #222222;
  --dss-dark-green: #004052;
  --dss-dark-green-text: #005a70;
  --dss-light-green: #65c3b3;
  --dss-teal-text: #005a70;
  --disabled-button-gray: #a7a7a7;
  --border-green: #57818e;
  --border-purple: #926da7;
  --action-primary: #4d4d4d;
  --action-hover-background: #f6f6f6;
  --action-hover: #202020;
  --error-red: #b30700;
}
