.factsheetTable_selectLabel {
  color: var(--primary-black) !important;
}

.factsheetTable_header {
  color: #31345b;
  font: normal normal 600 14px/18px Open Sans;
  letter-spacing: 0px;
  text-transform: uppercase;
  padding-left: 2rem !important;
}

.factsheetTable_seperator {
  display: none;
  visibility: hidden;
}

.factsheetTable_header_background {
  background: #f7f9fc 0% 0% no-repeat padding-box;
}

.factsheetTable_row {
  font: normal normal 400 14px/18px Open Sans;
  letter-spacing: 0px;
  color: #31345b;
}

.factsheetTable_cell_padding {
  padding-left: 2rem !important;
}

.factsheetTable_top_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
}

.factsheetTable_TopContainer {
  display: flex;
}

.factsheetTable_AddButton {
  margin-left: auto !important;
  align-self: center;
  margin-right: 25px !important;
}

.factsheetTable_DownloadText {
  padding-left: 0.75rem;
  font: normal normal normal 14px/13px Open sans;
  letter-spacing: 0px;
  min-width: 5rem;
  text-align: center;
}

.factsheetTable_Subheader {
  text-align: left;
  font: normal normal 600 20px/28px Open Sans !important;
  letter-spacing: 0px !important;
  color: var(--primary-purple) !important;
  padding-left: 1.5rem;
  padding-bottom: 0.625rem;
  align-self: end;
}

.factsheetTable_SubheaderCollapsed {
  text-align: left;
  font: normal normal 600 20px/28px Open Sans !important;
  letter-spacing: 0px !important;
  color: var(--primary-purple) !important;
  padding: 1.5rem;
  padding-right: 0px;
}

.factsheetTable_Loading {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.factsheetTable_closeIcon {
  margin-right: 1rem !important;
  font-size: xx-large !important;
  font-weight: bolder !important;
  color: var(--primary-purple) !important;
  min-width: 32px;
}

.factsheetTable_closeIconImg {
  min-width: 1.3em;
  min-height: 1.3em;
}

.factsheetTable_accordion_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 10px !important;
  opacity: 1;
  margin: 0px !important;
}

.factsheetTable_accordion {
  padding: 0px !important;
}

.factsheetTable_accordion_root {
  margin: 0px !important;
}

.factsheetTable_accordion_rootExpanded {
  margin: 0rem !important;
  padding-top: 1.0625rem;
  padding-bottom: 0.625rem;
}

.factsheetTable_accordion_details {
  padding: 0px !important;
}

.factsheetTable_icon {
  width: 1.25rem;
  height: 1.25rem;
}

.factsheetTable_button {
  min-width: 128px;
  min-height: 58px;
}

.factsheetTable_button_text {
  font: normal normal normal 14px/13px Open sans;
  letter-spacing: 0px;
  min-width: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.factsheetTable_button_icon {
  margin-right: 0.5rem;
}

.factsheetTable_cell_content {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
