.NoticeBriefs_HeaderText {
  padding-top: 12px;
  width: 50%;
  white-space: pre-wrap;
}

.NoticeBriefs_adminPadding {
  padding: 0 1.75rem 0;
  margin-top: -4.375rem;
}
