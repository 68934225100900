.visibilityDialog_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem !important;
  height: 12.875rem;
}

.visibilityDialog_CloseContainer {
  margin-left: auto;
}

.visibilityDialog_CloseIcon {
  padding: 1rem;
}

.visibilityDialog_Text {
  text-align: left;
  font: normal normal bold 1.5rem/1.875rem Open Sans;
  letter-spacing: 0.01125rem;
  margin-top: 0rem;
  color: #5f277e;
  opacity: 1;
}

.visibilityDialog_ButtonContainer {
  justify-content: space-around !important;
  width: 55%;
  margin-left: auto;
  margin-right: auto;
}

.visibilityDialog_InnerButtonContainer {
  width: 27.6875rem;
  display: flex;
  justify-content: space-between;
}

.visibilityDialog_Button {
  width: 12.75rem;
}

.visibilityDialog_ButtonText {
  margin: 0rem !important;
  padding-right: 3rem;
  padding-left: 3rem;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}

.visibilityDialog_Header {
  display: flex;
  flex-direction: column;
  margin-left: 2.313rem;
}

.visibilityDialog_icon {
  width: 6.25rem !important;
  height: 6.25rem !important;
  align-self: center;
}

.visibilityDialog_smallText {
  text-align: left;
  font: normal normal normal 1rem/1.5625rem Open Sans;
  letter-spacing: 0.0075rem;
  color: #000000;
  opacity: 1;
  margin-top: -1.188rem;
  margin-bottom: 1.875rem;
}

.visibilityDialog_checkBoxText {
  margin-right: 0.625rem;
  font: normal normal 600 1.25rem/1.75rem Open Sans;
}
