.pageNotFound {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 10vh;
  padding-bottom: 30vh;
  flex: 1;
}

.pageNotFoundText {
  color: var(--primary-purple) !important;
  font-size: large !important;
  font-weight: 600 !important;
}
