@media (min-width: 1023px) {
  .header_gutter {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .headerButtonText {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .headerMobileMenuButton {
    display: none !important;
  }
  .headerMobileDrawer {
    display: none !important;
  }
  .headerTitle {
    font: normal normal bold 1.625rem/2.8125rem Open Sans !important;
    white-space: nowrap;
  }
  .headerOption {
    font: normal normal bold 1rem/2rem Open Sans !important;
  }
  .headerOptionRegular {
    font: normal normal 600 1rem/2rem Open Sans !important;
  }
  .headerButtonText {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}

@media (min-width: 1440px) {
  .header_gutter {
    padding-left: 8.875rem !important;
    padding-right: 8.875rem !important;
  }
  .headerButtonText {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@media (max-width: 1023px) {
  .headerOption {
    font: normal normal bold 1rem/1.25rem Open Sans !important;
  }
  .headerOptionRegular {
    font: normal normal 600 1rem/1.25rem Open Sans !important;
  }
  .headerTitle {
    font: normal normal bold 1.25rem/1.5rem Open Sans !important;
    white-space: pre-wrap;
  }
  .headerButtonText {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
}

.headerPadding {
  background: var(--primary-white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0.1875rem 0.375rem #60617029;
  opacity: 1;
  display: flex;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.headerTitle {
  text-align: left;
  letter-spacing: 0.04875rem;
  color: var(--primary-purple) !important;
  opacity: 1;
  cursor: pointer;
  padding-right: 0.625rem;
  padding-bottom: 0.1875rem;
  flex-shrink: 3;
}

.headerOptionSelected {
  color: var(--primary-purple) !important;
}

.headerOptionRegular {
  color: #101010;
}

.headerOption {
  text-align: center;
  opacity: 1;
  cursor: pointer;
  margin-left: 3% !important;
  margin-right: 3% !important;
  padding-bottom: 0.1875rem;
  flex-shrink: 2;
}

.headerOption:hover {
  border-bottom: 0.1875rem solid var(--primary-purple);
  padding-bottom: 0px;
}

.headerOptionDropdown {
  text-align: center;
  letter-spacing: 0.03rem !important;
  opacity: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 0.625rem !important;
  padding-bottom: 0.1875rem;
  flex-shrink: 2;
}

.headerOptionDropdown:hover {
  border-bottom: 0.1875rem solid var(--primary-purple);
  padding-bottom: 0px;
}

.headerUpIcon {
  transform: rotate(-180deg);
  color: var(--primary-purple) !important;
  opacity: 1;
  margin-left: 0.3125rem;
}

.headerDownIcon {
  transform: rotate(0);
  color: var(--primary-purple) !important;
  opacity: 1;
  margin-left: 0.3125rem;
}

.headerCancelIcon {
  color: var(--primary-purple) !important;
  opacity: 1;
}

.headerButton {
  margin-left: auto !important;
}

.headerButtonText {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.headerMobileDrawerPadding {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.headerMobileDrawer {
  z-index: 10 !important;
  height: 100% !important;
}

.headerMobileDrawer_TextContainer {
  border: 1px solid #e8e8e8;
  opacity: 1;
  display: flex;
  padding: 5%;
}

.headerMobileDrawer_Chevron {
  color: #d4d4d4;
  margin-right: 4%;
}

.headerMobileDrawer_TextTitle {
  text-align: left;
  font: normal normal normal 1rem/1.375rem Open Sans !important;
  letter-spacing: 0px !important;
  color: var(--primary-black);
  opacity: 1;
}

.headerMobileDrawer_TextSubContainer {
  border: 1px solid #e8e8e8;
  opacity: 1;
  display: flex;
  padding: 4.5%;
  padding-left: 20%;
  align-items: center;
}

.headerMobileDrawer_TextSubContainer_Standalone {
  border: 1px solid #e8e8e8;
  opacity: 1;
  display: flex;
  padding: 6%;
  padding-left: 20%;
  align-items: center;
}

.headerMobileDrawer_TextSubtitle {
  text-align: left;
  font: normal normal 600 0.625rem/0.75rem Open Sans !important;
  letter-spacing: 0px !important;
  color: #004052 !important;
  text-transform: uppercase;
  opacity: 0.5;
}

.headerMobileDrawer_Accordion {
  margin: 0 !important;
  box-shadow: none !important;
}

.headerMobileDrawer_Accordion::before {
  display: none;
}

.headerMobileDrawer_AccordionSummary {
  padding: 5% !important;
}

.headerMobileDrawer_AccordionSummaryContent {
  margin: 0 !important;
}

.headerMobileDrawer_AccordionDetails {
  padding: 0px !important;
}

.headerMobileDrawer_Button {
  background: var(--primary-purple) 0% 0% no-repeat padding-box !important;
  border-radius: 1.5625rem !important;
  opacity: 1 !important;
  margin: 5% !important;
}

.headerMobileDrawer_ButtonText {
  text-align: center;
  font: normal normal 600 1rem/1.75rem Open Sans !important;
  letter-spacing: 0px !important;
  color: var(--primary-white) !important;
  opacity: 1;
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
