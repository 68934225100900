.modalLink_container {
  display: inline;
}

.modalLink_container:hover {
  cursor: pointer;
}

.modalLink_icon {
  vertical-align: middle;
  color: var(--primary-purple);
  height: 1.2rem;
}
