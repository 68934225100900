@media (min-width: 1440px) {
  .informationDialog_CloseIcon {
    padding: 1rem !important;
  }
}

@media (min-height: 520px) {
  .informationDialog_ContentContainer {
    max-height: 66vh !important;
  }
}

.informationDialog_Container {
  display: flex;
}

/* -webkit-scollbar: These css changes should surpress default Iphone adjustments to the web page 
which hides the scroll bar from users by default. These settings should force the scroll bar to appear. */
.informationDialog_Container::-webkit-scrollbar {
  -webkit-appearance: none;
}

.informationDialog_Container::-webkit-scrollbar:vertical {
  width: 11px;
}

.informationDialog_Containerame::-webkit-scrollbar:horizontal {
  height: 11px;
}

.informationDialog_Container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5);
}

.informationDialog_Container::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

.informationDialog_Border {
  border-color: #707070;
  opacity: 0.29;
  border-top: solid;
  border-top-width: 0.0625rem;
}

.informationDialog_ContentContainer {
  padding: 0px !important;
  display: flex;
}

.informationDialog_CloseContainer {
  margin-left: auto;
  position: sticky;
  top: 0;
}

.informationDialog_ButtonContainer {
  padding-bottom: 1.875rem !important;
  padding-top: 1.875rem !important;
  padding-left: 4.625rem !important;
  -webkit-box-pack: start !important;
  justify-content: flex-start !important;
}

.informationDialog_TextContainer {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  padding-right: 2.125rem;
  padding-left: 4.625rem;
  height: max-content;
}

.informationDialog_Content {
  white-space: break-spaces;
  text-align: left;
  font: normal normal normal 16px/25px Open Sans;
  letter-spacing: 0.12px;
  color: #000000de;
  padding-top: 1.5rem;
}

.informationDialog_HeaderText {
  text-align: left !important;
  font: normal normal bold 24px/25px Open Sans !important;
  letter-spacing: 0.18px !important;
  color: var(--primary-purple) !important;
  opacity: 1;
}
