.toolbar {
  min-height: 56px;
}

@media (min-width: 0px) and (orientation: landscape) {
  .toolbar {
    min-height: 48px;
  }
}

@media (min-width: 600px) {
  .toolbar {
    min-height: 64px;
  }
}

.signoutDialog_container {
  display: flex;
  flex-direction: column;
}

.signoutDialog_CloseContainer {
  margin-left: auto;
}

.signoutDialog_CloseIcon {
  padding: 16px;
}

.signoutDialog_Text {
  text-align: center;
  font: normal normal bold 24px/30px Open Sans;
  letter-spacing: 0.18px;
  color: #5f277e;
  opacity: 1;
  padding-bottom: 24px;
}

.signoutDialog_ButtonContainer {
  justify-content: space-around !important;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 36px;
  margin-bottom: 48px;
}

.signoutDialog_ButtonText {
  margin: 0px !important;
  padding-right: 32px;
  padding-left: 32px;
  padding-bottom: 6px;
  padding-top: 6px;
}

.responsiveDrawer_userType_text {
  text-align: right !important;
  font: normal normal 400 12px/16px Open Sans !important;
  letter-spacing: 0.3px !important;
  color: #ffffff !important;
  opacity: 0.75;
}

.responsiveDrawer_userName_text {
  text-align: right !important;
  font: normal normal 500 12px/16px Open Sans !important;
  letter-spacing: 0.3px !important;
  color: #ffffff !important;
  opacity: 1;
}

.responsiveDrawer_companyName_text {
  text-align: right !important;
  font: normal normal 400 12px/16px Open Sans !important;
  letter-spacing: 0.3px !important;
  color: #ffffff !important;
  opacity: 0.75;
}
