.Contact_HeaderText {
  padding-top: 0.75rem;
  width: 50%;
  white-space: pre-wrap;
}

.Contact_adminPadding {
  padding: 1.75rem;
}

.contactInfo_top_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 0.1875rem 0.375rem #00000029;
  border-radius: 0.625rem;
  opacity: 1;
}

.contactInfo_content {
  padding: 1.75rem;
}
