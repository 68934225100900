.DownloadDialog_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0.5rem !important;
}

.DownloadDialog_CloseContainer {
  margin-left: auto;
}

.DownloadDialog_CloseIcon {
  padding: 1rem;
}

.DownloadDialog_Text {
  text-align: center;
  font: normal normal bold 1.5rem/1.875rem Open Sans;
  letter-spacing: 0.01125rem;
  color: #5f277e;
  opacity: 1;
}

.DownloadDialog_ButtonContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.DownloadDialog_centerflex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.DownloadDialog_Header {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.5rem !important;
}

.DownloadDialog_icon {
  width: 6.25rem !important;
  height: 6.25rem !important;
  align-self: center;
}

.DownloadDialog_smallText {
  white-space: pre-wrap;
  word-break: break-word;
  text-align: center;
  font: normal normal 600 1.25rem/1.75rem Open Sans;
  letter-spacing: 0rem;
  color: #31345b;
  opacity: 1;
  max-width: 80%;
  margin-bottom: 2rem;
}

.DownloadDialog_ProgressText {
  white-space: pre-wrap;
  text-align: center;
  font: normal normal 600 1.25rem/1.75rem Open Sans;
  letter-spacing: 0rem;
  color: #31345b;
  opacity: 1;
  margin-top: 2rem;
}

.DownloadDialog_highlight {
  text-decoration: underline;
  font: normal normal 600 1.25rem/1.75rem Open Sans;
  letter-spacing: 0rem;
  color: #14abef;
  cursor: pointer;
}

.DownloadDialog_Dropzone {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  padding-top: 1rem;
}

.DownloadDialog_progress {
  align-self: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.DownloadDialog_flexRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.DownloadDialog_checkBoxText {
  margin-right: 0.625rem;
  font: normal normal 600 1.25rem/1.75rem Open Sans;
  letter-spacing: 0rem;
  color: #515151;
  opacity: 1;
}

.DownloadDialog_dialogText {
  display: flex;
  justify-content: center;
  align-content: center;
  font: normal normal normal 1rem/1.5625rem Open Sans;
  flex-direction: row;
}

.DownloadDialog_dialogTextAlign {
  display: flex;
  align-content: flex-start;
  font: normal normal normal 1rem/1.5625rem Open Sans;
  flex-direction: row;
}

.DownloadDialog_uploadIcon {
  width: 5.625rem;
  height: 5.625rem;
  margin-left: auto;
  margin-right: auto;
}

.DownloadDialog_uploadButton {
  padding-left: 4.6875rem !important;
  padding-right: 4.6875rem !important;
}
