.stakeholderDocumentTable_Button {
  margin-right: 0.25rem !important;
}

.stakeholderDocumentTable_Delete {
  margin-right: 1rem !important;
}

.stakeholderDocumentTable_selectLabel {
  color: var(--primary-black) !important;
}

.stakeholderDocumentTable_header {
  color: #31345b;
  font: normal normal 600 0.8125rem/1.125rem Open Sans;
  letter-spacing: 0rem;
  text-transform: uppercase;
  padding-left: 2rem !important;
}

.stakeholderDocumentTable_header:focus {
  outline: none !important;
}

.stakeholderDocumentTable_header:focus-within {
  outline: none !important;
}

.stakeholderDocumentTable_seperator {
  display: none;
  visibility: hidden;
}

.stakeholderDocumentTable_header_background {
  background: #f7f9fc 0% 0% no-repeat padding-box;
}

.stakeholderDocumentTable_header_background:focus {
  outline: none !important;
}

.stakeholderDocumentTable_row {
  font: normal normal 500 0.8125rem/1.125rem Open Sans;
  letter-spacing: 0rem;
  color: #31345b;
}

.stakeholderDocumentTable_row.Mui-selected {
  background-color: rgba(0, 0, 0, 0) !important;
}

.stakeholderDocumentTable_row.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.stakeholderDocumentTable_cell_padding {
  padding-left: 2rem !important;
}

.stakeholderDocumentTable_cell_padding:focus {
  outline: none !important;
}

.stakeholderDocumentTable_top_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 0.1875rem 0.375rem #00000029;
  border-radius: 0.625rem;
  opacity: 1;
}

.stakeholderDocumentTable_AddButton {
  margin-left: auto !important;
  align-self: center;
  width: 13.4375rem;
}

.stakeholderDocumentTable_DownloadText {
  padding-left: 0.75rem;
  font: normal normal normal 0.875rem/0.8125rem Open Sans;
  letter-spacing: 0rem;
  min-width: 5rem;
  text-align: center;
}

.stakeholderDocumentTable_Subheader {
  text-align: left;
  font: normal normal 600 1.25rem/1.75rem Open Sans !important;
  letter-spacing: 0rem !important;
  color: var(--primary-purple) !important;
}

.stakeholderDocumentTable_SubheaderCollapsed {
  text-align: left;
  font: normal normal 600 1.25rem/1.75rem Open Sans !important;
  letter-spacing: 0rem !important;
  color: var(--primary-purple) !important;
  padding: 1.5rem;
  padding-right: 0rem;
}

.stakeholderDocumentTable_SubheaderWithCaption {
  text-align: left;
  font: normal normal 600 1.25rem/1.75rem Open Sans !important;
  letter-spacing: 0rem !important;
  color: var(--primary-purple) !important;
}

.stakeholderDocumentTable_Loading {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.stakeholderDocumentTable_admin_closeIcon {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  font-size: xx-large !important;
  font-weight: bolder !important;
  color: var(--primary-purple) !important;
  min-width: 4rem;
}

.stakeholderDocumentTable_closeIcon {
  margin-left: auto !important;
  margin-right: 1rem !important;
  font-size: xx-large !important;
  font-weight: bolder !important;
  color: var(--primary-purple) !important;
  min-width: 4rem;
}

.stakeholderDocumentTable_accordion_box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0rem 0.1875rem 0.375rem #00000029 !important;
  border-radius: 0.625rem !important;
  opacity: 1;
}

.stakeholderDocumentTable_accordion {
  padding: 0rem !important;
}

.stakeholderDocumentTable_accordion_root {
  margin: 0rem !important;
}

.stakeholderDocumentTable_accordion_details {
  padding: 0rem !important;
}

.stakeholderDocumentTable_dropdown {
  margin-left: auto !important;
  align-self: center;
}

.stakeholderDocumentTable_select {
  max-width: 17.625rem;
  text-align: left !important;
  height: 3.5rem;
}

& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 0.0625rem solid var(--action-primary);
}

& .MuiOutlinedInput-input {
  font: normal normal normal 0.875rem/0.8125rem Open Sans !important;
  color: var(--action-primary) !important;
  padding: 0px !important;
  height: 100% !important;
  display: flex !important;
  align-items: center;
  padding-left: 1.875rem !important;
}

.stakeholderDocumentTable_cell_content {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  text-align: left;
}

.stakeholderDocumentTable_TableNameContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stakeholderDocumentTable_innerSubheading {
  color: #31345b;
  font: normal normal 500 0.8125rem/1.125rem Open Sans;
  margin: 0rem;
}
