.pageHeader {
  display: flex;
  flex-direction: column;
  padding: 1.875rem;
  padding-top: 1.25rem;
  height: 12.125rem;
}

.pageHeaderOverlay {
  height: 15.875rem;
}

.pageHeader_User {
  border-top: var(--border-green) 2px solid;
  background: var(--dss-dark-green);
}

.pageHeader_Admin {
  border-top: var(--border-purple) 2px solid;
  background: var(--primary-purple);
}

.pageHeader_TextContainer {
  flex: 2;
  text-align: left;
}

.pageHeader_SearchBarContainer {
  align-self: flex-end;
  flex: 1;
  width: 30%;
}

.pageHeader_HeaderText {
  padding-top: 0.625rem;
  white-space: pre-wrap;
  margin-right: 5% !important;
  color: var(--primary-white) !important;
}
