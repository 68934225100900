@media (min-width: 1024px) {
  .footer_gutter {
    padding-left: 8.875rem !important;
    padding-right: 8.875rem !important;
  }
}

@media (max-width: 1024px) {
  .footer_container {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
  .footer_text {
    margin-top: 1.5rem !important;
  }
  .footer_site_title {
    margin-bottom: 1.5rem !important;
  }
}

.footer {
  background: #004052 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: auto;
}

.footer_section1 {
  padding-top: 4.5rem;
  display: flex;
  align-items: center;
  margin: auto;
  max-width: 81.25rem;
}

.footer_site_title {
  text-align: left !important;
  font: normal normal bold 1.75rem/2.8125rem Open Sans !important;
  letter-spacing: 0.0525rem;
  color: var(--primary-white) !important;
  opacity: 1;
}

.footer_link_text {
  text-align: left !important;
  letter-spacing: 0px;
  color: var(--primary-white) !important;
  opacity: 1;
  margin-bottom: 1.25rem !important;
  cursor: pointer;
  display: inline-block;
}

.footer_link_text_last {
  text-align: left !important;
  letter-spacing: 0px;
  color: var(--primary-white) !important;
  opacity: 1;
  margin-bottom: 0px !important;
  cursor: pointer;
  display: inline-block;
}

.footer_text {
  text-align: left !important;
  padding-left: 2.5rem;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  border-left: solid;
  border-left-width: thin;
  text-align: left;
  font: normal normal normal 0.875rem/1.6875rem Open Sans !important;
  letter-spacing: 0.01875rem;
  color: var(--primary-white) !important;
  opacity: 1;
}

.footer_copyright {
  text-align: center;
  letter-spacing: 0px;
  color: var(--primary-white) !important;
  opacity: 1;
  font: normal normal normal 0.875rem/1.2rem Open Sans !important;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
